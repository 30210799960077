var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash/debounce';
import { ApiResourceName } from '@shared/types/api/api';
import { fetchResource, fetchResourceCollection } from '@backoffice/utils/api';
import { uuidFromIri } from '@shared/utils/iri';
import { CONFIG } from '@shared/environment/environment';
import TemplateEditor from '@backoffice/components/TemplateEditor.vue';
import TemplateViewer from '@backoffice/components/TemplateViewer.vue';
let ConfigurationTemplatePage = class ConfigurationTemplatePage extends Vue {
    constructor() {
        super(...arguments);
        this.claimChoices = [];
        this.claimSearch = '';
        this.claimId = '';
        this.isFetching = false;
        this.isPreviewOpen = false;
    }
    get templateId() {
        return uuidFromIri(this.messageTemplate.id);
    }
    get title() {
        return this.messageTemplate.title;
    }
    handleSelect(claim) {
        this.claimId = claim ? uuidFromIri(claim.id) : '';
    }
    async searchClaims(caseNumber) {
        this.isFetching = true;
        this.claimChoices = await fetchResourceCollection(ApiResourceName.Claims, this.$axios, { caseNumber }).then(({ data }) => data);
        this.isFetching = false;
    }
    preview() {
        if (this.claimId) {
            this.isPreviewOpen = true;
        }
    }
    goBack() {
        this.$router.push(this.localePath({ name: 'configuration-templates' }));
    }
    async save() {
        const url = new URL(`/${ApiResourceName.MessageTemplates}/${this.templateId}`, CONFIG.apiUrl);
        const { title, html } = this.messageTemplate;
        this.messageTemplate = await this.$axios.$put(url.href, { title, html });
    }
};
ConfigurationTemplatePage = __decorate([
    Component({
        components: { TemplateEditor, TemplateViewer },
        middleware: 'hasAccess',
        async asyncData({ params, $axios }) {
            const messageTemplate = await fetchResource(ApiResourceName.MessageTemplates, params.id, $axios).then(({ data }) => data);
            return { messageTemplate };
        },
        methods: {
            handleTyping: debounce(function (str) {
                if (!str)
                    return;
                return this.searchClaims(str);
            }),
        },
    })
], ConfigurationTemplatePage);
export default ConfigurationTemplatePage;
