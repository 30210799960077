var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CALL_TYPES_LABELS_AND_IDS } from '@backoffice/call/callConstants';
import { uuidFromIri } from '@shared/utils/iri';
import { formatDateTime } from '@shared/utils/date';
import { getStatus } from '@backoffice/claim/status';
import { UserRole } from '@shared/types/api/user';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { ApiResourceName } from '@shared/types/api/api';
const claim = namespace('claim');
let ClaimDeclaration = class ClaimDeclaration extends Vue {
    constructor() {
        super(...arguments);
        this.callType = '';
        this.callAnswer = 'no';
        this.callOrigin = '';
        this.callComment = '';
        this.callHistoric = [];
        this.claimStatus = null;
        this.claimStatusLabel = null;
        this.claimUpdating = false;
        this.submitError = null;
        this.CALL_TYPES_LABELS_AND_IDS = CALL_TYPES_LABELS_AND_IDS;
        this.parseCallType = (id) => {
            var _a;
            return (_a = CALL_TYPES_LABELS_AND_IDS.find((e) => e.id === id)) === null || _a === void 0 ? void 0 : _a.label;
        };
    }
    get isValid() {
        return this.callType && this.callAnswer && this.callOrigin && this.callComment;
    }
    get shouldDisplayForm() {
        return this.$hasAccessLevel(UserRole.MANAGER);
    }
    created() {
        this.$watch('initialClaim', this.fethCallTimeLineItems, { immediate: true });
    }
    async fethCallTimeLineItems() {
        var _a, _b;
        if (!this.initialClaim) {
            return;
        }
        const callTimelineItems = await this.$axios.$get(`${apiResourcePath(ApiResourceName.TimeLineItems)}?type=call&claim=${uuidFromIri((_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.id)}`);
        const claim = await this.$axios.$get(`/claims/${uuidFromIri((_b = this.initialClaim) === null || _b === void 0 ? void 0 : _b.id)}`);
        this.updateCallHistoric(callTimelineItems['hydra:member']);
        this.updateClaimStatus(claim);
        this.updateClaimStatusLabelFromClaimStatus(claim.status);
    }
    updateCallHistoric(callTimelineItems) {
        this.callHistoric = callTimelineItems.map((e) => ({
            comment: e.data.callComment,
            answer: e.data.callAnswer ? 'yes' : 'no',
            origin: e.data.callOrigin,
            type: this.parseCallType(e.data.callType),
            date: formatDateTime(e.date),
            id: e.id,
        }));
    }
    updateClaimStatus(claim) {
        this.claimStatus = claim.status;
    }
    updateClaimStatusLabelFromClaimStatus(status) {
        this.claimStatusLabel = this.$tc(getStatus(this.$config.insurerConfig, status).tradKey);
    }
    booleanParser(state) {
        return state === 'yes';
    }
    async onSubmit() {
        var _a;
        if (!this.isValid || this.isClaimUpdating) {
            return;
        }
        this.claimUpdating = true;
        const data = {
            callType: this.callType,
            callAnswer: this.booleanParser(this.callAnswer),
            callOrigin: this.callOrigin,
            callComment: this.callComment,
        };
        // Saving changes and updating store
        await this.$axios.$post('/call_creation' + ((_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.id), data);
        this.fethCallTimeLineItems();
        this.callType = '';
        this.callAnswer = 'no';
        this.callOrigin = '';
        this.callComment = '';
        this.claimUpdating = false;
    }
};
__decorate([
    claim.State('initialClaim')
], ClaimDeclaration.prototype, "initialClaim", void 0);
__decorate([
    claim.State('isInfoLoading')
], ClaimDeclaration.prototype, "isInfoLoading", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ClaimDeclaration.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Action('addDraftChanges')
], ClaimDeclaration.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('saveChanges')
], ClaimDeclaration.prototype, "saveChanges", void 0);
ClaimDeclaration = __decorate([
    Component({
        middleware: 'hasAccess',
        nuxtI18n: {
            paths: {
                en: '/claims/:id/call',
            },
        },
    })
], ClaimDeclaration);
export default ClaimDeclaration;
