var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { DamagesCompensationTable } from '@backoffice/components/claim/compensation/tables';
const claim = namespace('claim');
let ClaimCompensation = class ClaimCompensation extends Vue {
    damagesRows(sectionName) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        // If a draft compensation table exists, use it
        if ((_c = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.draft) === null || _b === void 0 ? void 0 : _b.compensationPresentation) === null || _c === void 0 ? void 0 : _c.sections[sectionName]) {
            return ((_f = (_e = (_d = this.info) === null || _d === void 0 ? void 0 : _d.claim.draft.compensationPresentation) === null || _e === void 0 ? void 0 : _e.sections[sectionName]) !== null && _f !== void 0 ? _f : {});
        }
        return ((_j = (_h = (_g = this.info) === null || _g === void 0 ? void 0 : _g.compensationTable) === null || _h === void 0 ? void 0 : _h.sections[sectionName]) !== null && _j !== void 0 ? _j : {});
    }
};
__decorate([
    claim.State('info')
], ClaimCompensation.prototype, "info", void 0);
ClaimCompensation = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            DamagesCompensationTable,
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/compensation',
            },
        },
    })
], ClaimCompensation);
export default ClaimCompensation;
